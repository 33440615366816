import type { AuditLogGridResponse, AuditLogResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const apiEndpointFactory =
  constructApi<AuditLogResponse>('auditlogs').withTags('AUDIT_LOG');
const api = apiEndpointFactory.inject(() => ({
  getAuditLog:
    apiEndpointFactory.getWithFiltersAndPaging<AuditLogGridResponse>(
      'get_audit_log',
    ),
}));

export const { useLazyGetAuditLogQuery } = api;
